import L from 'leaflet'
const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://integrate.vanguarder.com'
// const baseUrl = 'https://integrate.vanguarder.com'
// const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://api.asgard.goldenm.dev'
const hereTileUrlLight = 'https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png8?apiKey=ZtUEs-cgbrNFyCUgD_GQ3P7Rurs9jBHlkvkyGz7V3xY&ppi=320'
const hereTileUrlDark = 'https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.night/{z}/{x}/{y}/512/png8?apiKey=ZtUEs-cgbrNFyCUgD_GQ3P7Rurs9jBHlkvkyGz7V3xY&ppi=320'
const hereAttrib = '&copy; HERE 2019'

const LayerLight = L.tileLayer(hereTileUrlLight, {
  attribution: hereAttrib
})
const LayerDark = L.tileLayer(hereTileUrlDark, {
  attribution: hereAttrib
})

const inititalCoordinates = {
  latitude: 53.456644,
  longitude: -2.295685
}

const gisUrl = 'https://tempgis.goldenm.dev/nominatim/'

/** 
 * INTERNAL IP ADDRESS MODE
 * Use when you want to test with your mobile device or other laptops/pcs in your network
 *
 * Also, you need wake up your Django server using this command:
 * `pipenv run python manage.py runserver 0.0.0.0:8000`
 */
// const baseUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.68.110:8000' : 'https://alpha.goffice.io'

/**
 * Ngrok public test, you need this tool and change the baseURL of "API Service"
 * https://ngrok.com/
 */
// const baseUrl = 'https://f1dd3c6173aa.ngrok.io'
const reportUrl = `${baseUrl}/static/`

export {
  baseUrl,
  LayerLight,
  LayerDark,
  inititalCoordinates,
  gisUrl,
  reportUrl
}