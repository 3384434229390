import { i18n } from '@/plugins/i18n'

const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
} 
export default {
  defaultObject () {
    return {
      id: null,
      name: '',
      dataSource: '',
      criteria: [],
      exceptionConfiguration: {
        exceptionDays: [],
        exceptionTime: []
      },
      templateStructure: [],
      isScheduled: false,
      scheduleType: 'NONE',
      scheduleTime: 0,
      scheduleDayOfMonth: 0,
      scheduleDayOfWeek: 0,
      emails: [],
      relationType: 'UNIT',
      unitsIds: [],
      groupsIds: []
    }
  },
  defaultCriteria () {
    return {
      field: '',
      value: '',
      operator: ''
    }
  },
  availableFieldsSnapshot () {
    return [
      {
        text: i18n.t('newReports.snapshots.criteria.geofenceName'),
        operators: [
          {
            text: i18n.t('newReports.snapshots.operators.geofence.inside'),
            value: 'inside'
          },
          {
            text: i18n.t('newReports.snapshots.operators.geofence.outside'),
            value: 'outside'
          }
        ],
        value: 'GEOFENCE'
      },
      {
        text: i18n.t('newReports.snapshots.criteria.lastServiceDate'),
        value: 'LASTSERVICEDATE'
      },
      {
        text: i18n.t('newReports.snapshots.criteria.nextServiceDate'),
        value: 'NEXTSERVICEDATE'
      },
      {
        text: i18n.t('newReports.snapshots.criteria.lastMot'),
        value: 'LASTMOT'
      },
      {
        text: i18n.t('newReports.snapshots.criteria.nextMot'),
        value: 'NEXTMOT'
      },
      {
        text: i18n.t('newReports.snapshots.criteria.OwnerDepot'),
        options: [
          {
            text: i18n.t('newReports.snapshots.operators.geofence.inside'),
            value: 'inside'
          },
          {
            text: i18n.t('newReports.snapshots.operators.geofence.outside'),
            value: 'outside'
          }
        ],
        value: 'OWNERDEPOT'
      },
      {
        text: i18n.t('newReports.snapshots.criteria.vehicleType'),
        value: 'VEHICLETYPE'
      },
      {
        text: i18n.t('newReports.snapshots.criteria.trailerStyle'),
        value: 'TRAILERSTYLE'
      },
      {
        text: i18n.t('newReports.snapshots.criteria.tpmsActive'),
        value: 'TPMSACTIVE'
      },
      {
        text: i18n.t('newReports.snapshots.criteria.ebpmsActive'),
        value: 'EBPMSACTIVE'
      },
      {
        text: i18n.t('newReports.snapshots.criteria.tailLift'),
        value: 'TAILLIFT'
      },
      {
        text: i18n.t('newReports.snapshots.criteria.VOR'),
        value: 'VOR'
      }
    ]
  },
  availableFieldsMessages () {
    return []
  },
  availableFieldsEvents () {
    return []
  },
  yesOrNo () {
    return [
      {
        text: i18n.t('newReports.snapshots.criteria.yes'),
        value: 'true'
      },
      {
        text: i18n.t('newReports.snapshots.criteria.no'),
        value: 'false'
      }
    ]
  },
  insideOutside () {
    return [
      {
        text: i18n.t('newReports.snapshots.operators.geofence.inside'),
        value: 'inside'
      },
      {
        text: i18n.t('newReports.snapshots.operators.geofence.outside'),
        value: 'outside'
      }
    ]
  },
  previousOptions () {
    return [
      {
        text: i18n.t('newReports.snapshots.operators.previousOptions.previous15Days'),
        value: '15'
      },
      {
        text: i18n.t('newReports.snapshots.operators.previousOptions.previous30Days'),
        value: '30'
      }
    ]
  },
  nextOptions () {
    return [
      {
        text: i18n.t('newReports.snapshots.operators.nextOptions.next15Days'),
        value: '15'
      },
      {
        text: i18n.t('newReports.snapshots.operators.nextOptions.next30Days'),
        value: '30'
      }
    ]
  },
  availableOptionsByField (state, getters, rootState) {
    return {
      GEOFENCE: rootState.geofences.list.map(geofence => { return { text: geofence.name, value: geofence.id } }),
      OWNERDEPOT: getters.insideOutside,
      LASTSERVICEDATE: getters.previousOptions,
      NEXTSERVICEDATE: getters.nextOptions,
      LASTMOT: getters.previousOptions,
      NEXTMOT: getters.nextOptions,
      VEHICLETYPE: rootState.geofences.list.map(geofence => { return { text: geofence.name, value: geofence.id } }),
      TRAILERSTYLE: rootState.geofences.list.map(trailerStyle => { return { text: trailerStyle.name, value: trailerStyle.id } }),
      TPMSACTIVE: getters.yesOrNo,
      EBPMSACTIVE: getters.yesOrNo,
      TAILLIFT: getters.yesOrNo,
      VOR: getters.yesOrNo,
      '': []
    }
  },
  availableDays () {
    return [
      {
        text: i18n.t('newReports.snapshots.exception.days.monday'),
        value: 'MONDAY'
      },
      {
        text: i18n.t('newReports.snapshots.exception.days.tuesday'),
        value: 'TUESDAY'
      },
      {
        text: i18n.t('newReports.snapshots.exception.days.wednesday'),
        value: 'WEDNESDAY'
      },
      {
        text: i18n.t('newReports.snapshots.exception.days.thursday'),
        value: 'THURSDAY'
      },
      {
        text: i18n.t('newReports.snapshots.exception.days.friday'),
        value: 'FRIDAY'
      },
      {
        text: i18n.t('newReports.snapshots.exception.days.saturday'),
        value: 'SATURDAY'
      },
      {
        text: i18n.t('newReports.snapshots.exception.days.sunday'),
        value: 'SUNDAY'
      }
    ]
  },
  availableParametersVehicle () {
    // Generate one option for each value
    const options = [
      'name',
      'plate'
    ]
    return options.map(option => {
      return {
        text: i18n.t('newReports.templateStructure.' + `vehicle${capitalize(option)}`),
        value: `vehicle${capitalize(option)}`
      }
    })
  },
  availableParametersTrailerProfile () {
    // Generate one option for each value
    const options = [
      'trailerStyle',
      'vin',
      'vor',
      'plannedMonthlyMileage',
      'ebpmsActive',
      'tpmsActive',
      'height',
      'weight',
      'serviceInterval',
      'tailLift',
      'moffetMounts',
      'lastService',
      'ownerDepotId',
      'ebsValveType',
      'group',
      'vehicleType',
      'vehicleMake',
      'vehicleGrossWeight',
      'operator',
      'description',
      'tyreSize',
      'axleMake',
      'colour',
      'year',
      'suspensionMake',
      'brakeSize',
      'lightingMake',
      'nextServiceDate',
      'lastMotDoe',
      'nextMotDoe'
      // 'homeDepot',
      // 'serviceFrequency'
    ]
    return options.map(option => {
      return {
        text: i18n.t(`newReports.templateStructure.trailerProfile${capitalize(option)}`),
        value: `trailerProfile${capitalize(option)}`
      }
    })
  },
  availableParametersVehicleType () {
    const options = [
      'placardPressure',
      'axleQuantity',
      'tyrePerAxle'
    ]
    return options.map(option => {
      return {
        text: i18n.t(`newReports.templateStructure.vehicleType${capitalize(option)}`),
        value: `vehicleType${capitalize(option)}`
      }
    })
  },
  availableParametersSnapshot () {
    const options = [
      'lastReportDate',
      'lastPosition',
      'lastAddress',
      'lastSpeed',
      'lastOdometer',
      'messageQuantity',
      'configuredEventsQuantity',
      'overweightEventsQuantity',
      'dtcEventsQuantity',
      'yellowDtcEventsQuantity',
      'redDtcEventsQuantity',
      'ebpmsValue',
      'mileageForInterval',
      'overdueService',  
      'overdueMOT',
      'ebpmsColor',
      'currentState', // we are here
      'timeInState',
      'overweightEventsDistance',
      'yellowDtcEventsDistance',
      'redDtcEventsDistance',
      'tpmsPressureEventsQuantity',
      'tpmsDistance',
      'mileageForIntervalMiles',
      'mpgForInterval'
      // 'workBeginDate',
      // 'workEndDate',
      // 'startAddress',
      // 'anyActiveFaults',
      // 'uniqueYellowDtcEvents',
      // 'uniqueRedDtcEvents',
      // 'mileageActualVsPlanned',
      // 'mileageActualVsPlannedMiles'
    ]
    return options.map(option => {
      return {
        text: i18n.t(`newReports.templateStructure.snapshot${capitalize(option)}`),
        value: `snapshot${capitalize(option)}`
      }
    })
  }
}